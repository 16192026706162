/* ErrorBoundary.css */

.error-fallback {
    background-color: #ffe6e6; /* Light red background */
    color: #cc0000; /* Dark red text */
    border: 1px solid #ff4d4d;
    border-radius: 8px;
    padding: 30px 20px;
    max-width: 400px;
    width: 90%;
    margin: 40px auto;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .error-fallback h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }
  
  .error-fallback p {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .error-fallback button {
    padding: 10px 20px;
    background-color: #cc0000;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .error-fallback button:hover {
    background-color: #b30000;
  }
  